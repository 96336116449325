<template>
  <div class="home">
    <div class="banner">
        <div class="banner-container">
          <div class="banner-content">
            <h2>关注成长，发掘价值</h2>
            <p>
              扬歌广告平台是以推动产品成长，发掘产品价值为主导，同时提供整合互联网产品和媒体资源等服务，协助开发者挖掘价值。
            </p>
            <!-- <p class="mini">
              平台主要服务于国内的初创互联网产品，助其推广、扩张及规模化成型。媒体服务主要依托于增值运营，深挖生态闭环资源，能够大幅有效的提升资源价值。
            </p>
            <p class="mini">
              平台海外服务可以为国际化产品提供标准化Google等资源对接，帮助其投放、运营及规模化，同时非标准化资源可以根据产品或媒体提供专项服务。
            </p> -->
          </div>
          <div class="banner-bg">
            <img src="../assets/img/home-bg.png" alt="bg">
            <div class="ani-box">
              <div>
                <img src="../assets/img/anm1.png" alt="ss">
              </div>
              <div>
                <img src="../assets/img/anm2.png" alt="ss">
              </div>
              <div>
                <img src="../assets/img/anm3.png" alt="ss">
              </div>
              <div>
                <img src="../assets/img/anm1.png" alt="ss">
              </div>
              <div>
                <img src="../assets/img/anm2.png" alt="ss">
              </div>
              <div>
                <img src="../assets/img/anm3.png" alt="ss">
              </div><!--其中一组1,2,3用于固定div盒子圆的大小-->
              <i></i><i></i><i></i> 
            </div>
            <div class="river">
              <img v-for='item in 2' :key='item' src="../assets/img/river.png" alt="">
            </div>
            <img src="../assets/img/jb.png" alt="">
            <img src="../assets/img/blx.png" alt="#">
          </div>
        </div>
    </div>
    <div class="ad-ability">
        <div class="ability-title">
          <h2>多维的平台服务</h2>
          <h6>扬歌平台目前四大核心：小程序插件、聚合SDK、H5、海外流量变现的简介及价值</h6>
          <div>
            <img src="../assets/img/bottom.png" alt="">
          </div>
        </div>
        <div class="ability-container">
            <div :class="['ability-detail',{animate:scrollAtHere}]">
              <div>
                <div>
                  <img src="../assets/img/xcxcj.png" alt="">
                </div>
                <h5>小程序插件<span></span></h5>
                <p>针对微信小程序广告接入服务的一款自研插件，已通过微信平台审核上线。完全支持腾讯官方小程序广告接入，由腾讯云支持，流量安全性强。支持小程序流量植入、整形，流量真实性高，部分服务内容支持h5广告落地页或小程序原生页面落地页广告推广及产品接入。</p>
              </div>
              <div>
                <div>
                  <img src="../assets/img/jhsdk.png" alt="">
                </div>
                <h5>聚合SDK<span></span></h5>
                <p>整合了国内95%以上的一线sdk服务，实现资源互补，并为不同产品提供不同的对接方案帮助其获取稳定的广告预算资源。自有运营体系能够很好的实现流量的价值提升。</p>
              </div>
              <div>
                <div>
                  <img src="../assets/img/h5.png" alt="">
                </div>
                <h5>H5<span></span></h5>
                <p>基于垂直行业的wap增值变现及定制化流量服务。</p>
              </div>
              <div>
                <div>
                  <img src="../assets/img/hw.png" alt="">
                </div>
                <h5>海外流量<span></span></h5>
                <p>标准化平台服务开户，产品推广，运营投放；非标准化资源转换及变现。</p>
              </div>
            </div>
        </div>
    </div>
    <div :class="['efficient',{arrive:arriveEfficient}]">
      <h2 :class="{arrive:arriveEfficient}">高效的平台能力</h2>
      <div :class="['efficient-container',{arrive:arriveEfficient}]">
        <div>
          <img src="../assets/img/power.png" alt="">
        </div>
        <div>
          <h5>专业</h5>
          <h4></h4>
          <p>平台提供真正的全流程服务</p>
          <h5>创新</h5>
          <h4></h4>
          <p>自主研发的广告系统及运营体系</p>
          <h5>高值</h5>
          <h4></h4>
          <p>增值价值普遍高于市场20%-30%</p>
          <!-- <div>
            <span>了解更多</span>
          </div> -->
        </div>
      </div>
    </div>
    <div class="ad-type">
      <h2>丰富的广告场景</h2>
      <div class="type-container">
          <div class="img-container">
            <img src="../assets/img/scene_bg.png" alt="">
          </div>
          <div class="type-content">
            <div :style="{'top':typesPosition.type1}">
              <HomeAdStyle 
                type-title='Banner广告' 
                type-detail='在App顶/中/底部悬浮且可关闭的边栏广告' 
              />
            </div>
            <div :style="{'top':typesPosition.type2}">
              <HomeAdStyle 
                blackcolor  
                typeimg='home-2.png'
              />
              <HomeAdStyle 
                type-title='动态信息流' 
                type-detail='广告与App内容高度自然融合的信息流' 
                typeimg='home-3.png'
                class="imgReset"
              />
            </div>
            <div :style="{'top':typesPosition.type3}">
              <HomeAdStyle 
                type-title='激励视频广告' 
                typeimg='home-4.png'
                type-detail='完整观看视频广告以免费获得游戏奖励'
              />
            </div>
            <div :style="{'top':typesPosition.type4}">
              <HomeAdStyle 
                type-title='插屏广告' 
                typeimg='home-5.png'
                type-detail='在弹出窗口上展示广告'
              />
            </div>
          </div>
      </div>
    </div>
    <div class="carousel">
        <h2>精选合作案例</h2>
        <Carousel />
    </div>
    <div class="partner">
      <h2>主要合作伙伴</h2>
      <div>
        <img src="../assets/img/pt_tt.png" alt="">
        <img src="../assets/img/pt_tx.png" alt="">
        <img src="../assets/img/pt_bd.png" alt="">
        <img src="../assets/img/pt_dy.png" alt="">
        <img src="../assets/img/pt_jd.png" alt="">
        <img src="../assets/img/pt_mp.png" alt="">
        <img src="../assets/img/pt_qczj.png" alt="">
        <img src="../assets/img/pt_tb.png" alt="">
        <img src="../assets/img/pt_tq.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import HomeAdStyle from '../components/HomeAdType.vue'
import Carousel from '../components/Carousel.vue'
export default {
  components: {
    HomeAdStyle,Carousel
  },
  data() {
    return {
      scrollTop:0,
      scrollHelpNum:0,
      baseScrollPosi:0,
      scrollAtHere:false,//控制‘多维网站能力’部分icon图标转动
      arriveEfficient:false
    }
  },
  computed: {
    typesPosition(){
      let {baseScrollPosi} = this
      let
        type1 = baseScrollPosi + 160 + 'px',
        type2 = baseScrollPosi - 158 + 'px',
        type3 = baseScrollPosi + 76 + 'px',
        type4 = baseScrollPosi + 225 + 'px'
      return {
        type1,type2,type3,type4
      }
    }
  },
  watch: {
    scrollTop(newValue, oldValue) {//监控滚动条的位置，实现首页‘广告样式’部分的微滑动效果
      if(this.scrollHelpNum === 0){
        this.scrollHelpNum = 1
        let timer = setTimeout(()=>{//用一次性定时器做了一个节流
          this.scrollHelpNum = 0
          clearTimeout(timer)
        },100)
        this.baseScrollPosi = -130*(this.scrollTop-1000)/2500
      }
      if(newValue>1050 && newValue<2200){
        this.arriveEfficient = true
      }else{
        this.arriveEfficient = false
      }
    }
  },
  methods: {
    handleScroll(){
      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      // console.log(scrollTop)
      if(scrollTop >= 1000 && scrollTop <= 3700){
        this.scrollTop = scrollTop
      }
      if(scrollTop >= 500 && scrollTop <= 1200){
        this.scrollAtHere = true
      }else{
        this.scrollAtHere = false
      }
    }
  },
  mounted(){
    window.addEventListener('scroll',this.handleScroll,true)
  },
  destroyed(){
    window.removeEventListener('scroll',this.handleScroll,true)
  }
}
</script>
<style lang="less" scoped>
  @import url('../assets/css/theme-color.less');
  .home{
    .banner{
      overflow: hidden;
      height: 720px;
      .banner-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        .banner-content{
          width: 40%;
          overflow: hidden;
          h2{
            font-size: 50px;
            font-weight: 600;
          }
          p{
            font-size: 16px;
            margin-top: 30px;
            color: #535353;
            &.mini{
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 10px;
              font-size: 14px;
              height: 60px;
              margin-right: 30px;
              border: 1px solid @themeColor;
              border-radius: 6px;
              background: none;
              color: @themeColor;
            }
          }
        }
        .banner-bg{
          width: 50%;
          height: 100%;
          position: relative;
          >img:nth-child(1){
            position: absolute;
            right: -400px;
            top: 60px;
            width: 200%;
          }
          >img:nth-last-of-type(2){
            position: absolute;
            right: 448px;
            top: 344px;
            width:103px;
            z-index: 100;
          }
          >img:last-child{
            position: absolute;
            right: -190px;
            top: 310px;
            width:710px;
            height: 80px;
            transform: rotateZ(-5deg);
          }
          >.ani-box{
            position: absolute;
            top: -21px;
            left: 129px;
            width: 100%;
            height: 100%;
            >div{
              position: absolute;
              padding: 8px;
              border-radius: 50%;
              border:1px solid @themeColor;
              img{
                width: 60px;
              }
              &:nth-of-type(1){
                top: 160px;
                right: 60px;
                border: none;
                animation: anm1Move 16s linear 2s infinite alternate;
                z-index: 10;
              }
              &:nth-of-type(2){
                top: 260px;
                right: 160px;
                border: none;
                animation: anm2Move 16s linear 2s infinite alternate;
                z-index: 10;
              }
              &:nth-of-type(3){
                top: 180px;
                right:460px;
                border: none;
                animation: anm3Move 16s linear 2s infinite alternate;
                z-index: 10;
              }
              &:nth-of-type(4){
                top: 160px;
                right: 60px;
                box-shadow: 4px 4px 14px @themeColor;
                img{
                  opacity: 0;
                }
              }
              &:nth-of-type(5){
                top: 260px;
                right: 160px;
                img{
                  opacity: 0;
                }
                box-shadow: 4px 4px 14px @themeColor;
              }
              &:nth-of-type(6){
                top: 180px;
                right:460px;
                img{
                  opacity: 0;
                }
                box-shadow: 4px 4px 14px @themeColor;
              }
            }
            i{
              position: absolute;
              border-top:1px solid @themeColor;
              &:nth-of-type(1){
                width: 150px;
                top: 267px;
                right: 42px;
                transform: rotateZ(-47deg);
              }
              &:nth-of-type(2){
                width: 396px;
                top: 170px;
                right: 96px;
                transform: rotateZ(-3deg);
              }
              &:nth-of-type(3){
                width: 310px;
                top: 297px;
                right: 199px;
                transform: rotateZ(15deg);
              }
            }
          }
          >.river{
            width: 750px;
            height:152px;
            position: relative;
            top: 300px;
            img{
              position: absolute;
              width: 60px;
              &:nth-of-type(odd){
                top: 88px;
                left: 20px;
                animation: river1Move 16s linear 1s infinite;
              }
              &:nth-of-type(even){
                top:138px;
                left: 20px;
                animation: river2Move 16s linear 1s infinite;
              }
            }
          }
        }
      }
    }
    .ad-ability{
      background: white;
      .ability-title{
        width: 100%;
        background: @themeColor;
        padding: 80px 0;
        h2{
          font-weight: 600;
          font-size: 38px;
          text-align: center;
          color: white;
        }
        h6{
          margin: 26px 0;
          text-align: center;
          font-size: 20px;
          color: white;
        }
        div{
          text-align: center;
          img{
            width: 36px;
          }
        }
      }
      .ability-container{
        width: 1200px;
        margin: 0 auto;
        padding: 80px 0 160px;
        .ability-detail{
          display: flex;
          justify-content: space-between;
          &.animate{
              >div{
                &:nth-child(1){
                  >div{
                    animation: divRotate 2s linear 1s;
                  }
                }
                &:nth-child(2){
                  >div{
                    animation: divRotate 2s linear 3s;
                  }
                }
                &:nth-child(3){
                  >div{
                    animation: divRotate 2s linear 5s;
                  }
                }
                &:last-child{
                  >div{
                    animation: divRotate 2s linear 7s;
                  }
                }
              }
          }
          >div{
            width: 23%;
            p{
              color: #989898 !important;
              text-align: center;
            }
            >div{
              img{
                width: 58px;
              }
            }
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            margin-top: 20px;
            margin-right:36px;
            &:last-child{
              margin-right:0;
            }
            h5{
              font-weight: 600;
              font-size: 24px;
              margin: 36px 0 26px;
            }
            span{
              position: absolute;
              top: 146px;
              left: 116px;
              width: 40px;
              height: 2px;
              background: @themeColor;
            }
            p{
              margin-top: 20px;
              color: #797979;
              font-size: 14px;
            }
          }
        }
      }
    }
    .efficient{
      height: 800px;
      background: white;
      padding-top: 80px;
      transition: 1.3s;
      &.arrive{
        background: #f7f8f9;
      }
      h2{
        font-weight: 600;
        font-size: 38px;
        text-align: center;
        padding: 30px 0 80px;
        opacity: 0;
        transition: .9s;
        &.arrive{
          opacity: 1;
        }
      }
      .efficient-container{
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        >div{
          width: 46%;
          transition: .9s;
          >img{
            width: 80%;
          }
          h5{
            font-size: 24px;
            font-weight: 600;
            margin-top: 30px;
          }
          h4{
            width: 36px;
            height: 2px;
            background: @themeColor;
            margin: 16px 0;
          }
          p{
            color: #777;
          }
          >div{
            margin-top: 60px;
            cursor: pointer;
            width: 150px;
            height: 56px;
            border: 2px solid @themeColor;
            border-radius: 28px;
            text-align: center;
            transition: .3s;
            span{
              font-size: 24px;
              color: @themeColor;
              line-height: 52px;
              transition: .3s;
            }
            &:hover{
              background: @themeColor;
              span{
                color: white;
              }
            }
          }
          &:nth-child(1){
            opacity: 0;
            transform: translate(-100px,10px);
          }
          &:nth-child(2){
            opacity: 0;
            transform: translate(100px,10px);
          }
        }
        &.arrive{
          >div{
            &:nth-child(1){
              opacity: 1;
              transform: translate(0px,0px);
            }
            &:nth-child(2){
              opacity: 1;
              transform: translate(0px,0px);
            }
          }
        }
      }
    }
    .ad-type{
      padding-bottom: 420px;
      h2{
        font-weight: 600;
        font-size: 38px;
        text-align: center;
        margin: 80px 0 240px;
      }
      .type-container{
        height: 635px;
        position: relative;
        background: rgba(132, 146, 223,.6);
        .img-container{
          overflow: hidden;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          >img{
            position: absolute;
            top: 0;
            left: -10px;
            width: 105%;
            height: 100%;
            opacity: .8;
          }
        }
        .type-content{
          width: 1200px;
          height: 635px;
          margin: 0 auto;
          position: relative;
          >div{
            width: 25%;
            position: absolute;
            &:nth-child(1){
              transition: .1s;
              left: -14px;
            }
            &:nth-child(2){
              height: 1200px;
              left: 293px;
              /deep/ .home-ad-type.imgReset{
                img{
                  margin-top: 10px;
                  width: 103%;
                }
              }
              transition: .3s;
            }
            &:nth-child(3){
              left: 597px;
              transition: .3s;
            }
            &:nth-child(4){
              left: 904px;
              transition: .3s;
            }
          }
        }
      }

    }
    .carousel{
      width: 100%;
      background: #f7f8f9;
      padding-bottom: 200px;
      h2{
        font-weight: 600;
        font-size: 36px;
        text-align: center;
        padding: 100px;
      }
    }
    .partner{
      height: 600px;
      h2{
        font-weight: 600;
        font-size: 38px;
        text-align: center;
        padding: 80px 0;
        margin-top: 30px;
      }
      div{
        display: flex;
        justify-content: center;
        img{
          width: 80px;
          margin: 30px;
        }
      }
    }
  }
    @keyframes anm1Move{
      0%   {
        top: 160px;
        right: 60px;
      }
      33%  {
        top: 260px;
        right: 160px;
      }
      66% {
        top: 180px;
        right:460px;
      }
      100% {
        top: 160px;
        right: 60px;
      }
  }
  @keyframes anm2Move{
      0%   {
        top: 260px;
        right: 160px;
      }
      33%  {
        top: 180px;
        right:460px;
      }
      66% {
        top: 160px;
        right: 60px;
      }
      100% {
        top: 260px;
        right: 160px;
      }
  }
  @keyframes anm3Move{
      0%   {
        top: 180px;
        right:460px;
      }
      33%  {
        top: 160px;
        right: 60px;
      }
      66% {
        top: 260px;
        right: 160px;
      }
      100% {
        top: 180px;
        right:460px;
      }
  }
  @keyframes river1Move{
      0%   {
        top: 88px;
        left: 20px;
      }
      25%  {
        top: 88px;
        left: 160px;
      }
      50% {
        top: 88px;
        left: 320px;
      }
      100% {
        top: 88px;
        left: 680px;
      }
  }
  @keyframes river2Move{
      0%   {
        top:138px;
        left: 20px;
      }
      25%  {
        top: 116px;
        left: 160px;
      }
      50% {
        top: 102px;
        left: 320px;
      }
      100% {
        top: 102px;
        left: 680px;
      }
  }
  @keyframes divRotate{
      0%   {
        transform: rotateY(0deg);
      }
      50%  {
        transform: rotateY(180deg);
      }
      100% {
        transform: rotateY(360deg);
      }
  }
</style>