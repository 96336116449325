<template>
    <div class="header">
        <div class="virtualHeight"></div>
        <div :class="['headerContent',{notfixed:!isFixed}]" >
            <div>
                <img src="../assets/img/logo.png" alt="">
                <!-- <span></span> -->
                <img src="../assets/img/logo-code.png" alt="">
            </div>
            <ul>
                <li 
                    v-for="item,index in routerList" 
                    :key='index'
                    :class="{active:activeIndex==index}"
                    @click="handleChangePage(index)"
                    >
                        <i>{{item.title}}</i>
                        <span></span>
                </li>
                <li class="login">
                    <a href="https://ssp.adyounger.com/#/login">
                        登录
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    import {Bus} from '../utils/bus.js'
    export default {
        data() {
            return {
                activeIndex: 0,
                isFixed:true,
                routerList:[{
                        path:'/',
                        title:'首页'
                    },{
                        path:'/adstyle',
                        title:'广告形态'
                    },{
                        path:'/sdk',
                        title:'扬歌SDK'
                    },{
                        path:'/service',
                        title:'服务流程'
                    },
                    {
                        path:'/dynamic',
                        title:'关于我们'
                    },
                    // {
                    //     path:'/helpcenter',
                    //     title:'帮助中心'
                    // },
                    {
                        path:'/contact',
                        title:'联系我们'
                    }]
            }
        },
        methods: {
            handleChangePage(i) {
                this.activeIndex = i
                this.isFixed = i === 1 ? false : true
                this.$router.history.current.path !== this.routerList[i].path
                &&
                this.$router.push(this.routerList[i].path)
                this.routerList[i].path === '/helpcenter' && Bus.$emit('changeComponentShow') 
            }
        },
        watch: {
          $route(to,from){
              console.log(to.path)
            this.isFixed = to.path === '/adstyle' ? false : true
            if(to.path === '/' || to.path ==='/adstyle' || to.path ==='/sdk' || to.path ==='/dynamic'){
                Bus.$emit('changShowJoin',true)
            }else{
                Bus.$emit('changShowJoin',false)
            }
            if(to.path === '/helpcenter'){
                Bus.$emit('changeComponentShow')
            }
            document.getElementsByTagName('html')[0].scrollTop = 0
            let {routerList} = this
            for(let i in routerList){//解决刷新路由标题上方横横杠不显示
                if(to.path === routerList[i].path){
                    this.activeIndex = i 
                    return
                }
            }
            this.activeIndex = 4//若在帮助中心详情页刷新时，显示帮助中心
          }
        }
    }
</script>

<style lang="less" scoped>
    @import url('../assets/css/theme-color.less');
    .header{
        .virtualHeight{
            height: 78px;
        }
        .headerContent{
            border-bottom: 1px solid #ececec;
            height: 78px;
            width: 100%;
            background: white;
            position: fixed;
            padding:0 26px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            top: 0;
            left: 0;
            z-index: 1000;
            &.notfixed{
                position: absolute;
            }
            >div:nth-child(1){
                height: 100%;
                display: flex;
                align-items: center;
                img{
                    height: 100%;
                }
                img:first-child{
                    height: 68%;
                }
                span{
                    display: inline-block;
                    height: 80%;
                    width: .5px;
                    background: #dedede;
                    margin: 0 12px;
                }
            }
            ul{
                display: flex;
                align-items: center;
                li{
                    height:78px;
                    line-height: 78px;
                    margin: 0 18px;
                    padding: 0 2px;
                    position: relative;
                    cursor: pointer;
                    i{
                        width: 100%;
                        height: 100%;
                        color: #666;
                    }
                    span{
                        position: absolute;
                        top: 0;
                        left: 50%;
                        width: 0;
                        height: 4px;
                        transition: .3s;
                    }
                    &:nth-child(1){
                        padding: 0 16px;
                    }
                    &.active{
                        span{
                            background: @themeColor;
                            width: 100%;
                            left: 0;
                        }
                    }
                    &:hover{
                        span{
                            background: @themeColor;
                            width: 100%;
                            left: 0;
                        }
                    }
                    &.login{
                        text-align: center;
                        vertical-align: center;
                        a{
                            display: inline-block;
                            line-height: 34px;
                            width: 76px;
                            height: 34px;
                            background: @themeColor;
                            color: white;
                            border-radius: 19px;
                            transition: .3s;
                            &:hover{
                                background: #1d92b9;
                            }
                        }
                    }
                }
            }
        }
    }
</style>