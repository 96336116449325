<template>
    <div class="footer">
        <div class="bg">
            <img src="../assets/img/scene_bg.png" alt="">
        </div>
        <!-- <a href="tencent://message/?uin=35699190&Site=Sambow&Menu=yes">在线咨询</a> -->
        <div class="content">
            <div class="top">
                <div v-show='showJoin'>
                    <img src="../assets/img/logo.png" alt="">
                    <h4></h4>
                    <h2>现在开始您的应用创收之旅</h2>
                    <div @click="handleLogin">
                        立即合作
                    </div>
                    <img src="../assets/img/More.png" alt="">
                    <img src="../assets/img/More.png" alt="">
                    <img src="../assets/img/More.png" alt="">
                    <img src="../assets/img/More.png" alt="">
                </div>
            </div>
            <div class="bottom">
                <div>
<!--                    <span>重庆天行健科技有限公司</span>-->
                    <span>
                        <b><a href="https://www.adyounger.com/html/fwtk.html" target="_blank">服务条款</a></b>
                        |
                        <b><a href="https://www.adyounger.com/html/yszc.html" target="_blank">隐私政策</a></b>
                    </span>
                </div>
                <div>
                    <a href="https://beian.miit.gov.cn/">沪ICP备2021024255号-1</a>
                </div>
<!--                <span>liuzz@adyounger.com</span>-->
            </div>
        </div>
    </div>
</template>
<script>
    import {Bus} from '../utils/bus.js'

    export default {
        data() {
            return {
                showJoin: true
            }
        },
        methods: {
            handleLogin() {
                window.location.href = 'https://ssp.adyounger.com/'
            }
        },
        created() {
            Bus.$on('changShowJoin', (arg) => {
                this.showJoin = arg
            })
        }
    }
</script>

<style lang="less" scoped>
    @import url('../assets/css/theme-color.less');

    .footer {
        height: 286px;
        background: rgba(132, 146, 223, .6);
        position: relative;

        .bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                opacity: .8;
            }
        }

        .content {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;

            .top {
                height: 220px;

                > div {
                    width: 1200px;
                    height: 260px;
                    background: white;
                    margin: 0 auto;
                    position: relative;
                    top: -100px;
                    background-image: url('../assets/img/footer_bg.png');
                    border-radius: 12px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 90px;
                    }

                    h4 {
                        width: 36px;
                        height: 2px;
                        background: @themeColor;
                        margin: 12px 0px 16px 8px;
                    }

                    h2 {
                        font-size: 32px;
                        font-weight: 600;
                        letter-spacing: 2px;
                    }

                    > div {
                        width: 100px;
                        height: 38px;
                        line-height: 38px;
                        background: @themeColor;
                        text-align: center;
                        color: #efefef;
                        border-radius: 3px;
                        margin-top: 28px;
                        cursor: pointer;
                        transition: .3s;

                        &:hover {
                            color: white;
                        }
                    }

                    > img:not(:nth-of-type(1)) {
                        position: absolute;
                        opacity: .1;

                        &:nth-last-of-type(1) {
                            bottom: 0;
                            left: 0;
                        }

                        &:nth-last-of-type(2) {
                            bottom: 60px;
                            left: 180px;
                        }

                        &:nth-last-of-type(3) {
                            bottom: 70px;
                            left: 750px;
                        }

                        &:nth-last-of-type(4) {
                            top: 0;
                            right: 0;
                        }
                    }
                }
            }

            .bottom {
                height: 66px;
                width: 1200px;
                margin: 0 auto;
                border-top: 1px solid #767676;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: white;
                font-size: 14px;

                span:nth-child(1) {
                    margin-right: 150px;
                }

                b {
                    margin: 0 16px;
                }

                a {
                    color: white;
                }
            }
        }
    }
</style>